export const partners = [
    {
        id: 1,
        img: '/img/home/partner/1.png',
    },
    {
        id: 2,
        img: '/img/home/partner/2.png',
    },
    {
        id: 3,
        img: '/img/home/partner/3.png',
    },
    {
        id: 4,
        img: '/img/home/partner/4.png',
    },
    {
        id: 5,
        img: '/img/home/partner/5.png',
    },
    {
        id: 6,
        img: '/img/home/partner/6.png',
    },
    {
        id: 7,
        img: '/img/home/partner/7.png',
    },
    {
        id: 8,
        img: '/img/home/partner/8.png',
    },
    {
        id: 9,
        img: '/img/home/partner/9.png',
    },
    {
        id: 10,
        img: '/img/home/partner/10.png',
    },
    {
        id: 11,
        img: '/img/home/partner/11.png',
    },
    {
        id: 12,
        img: '/img/home/partner/12.png',
    },
    {
        id: 13,
        img: '/img/home/partner/13.png',
    },
    {
        id: 14,
        img: '/img/home/partner/14.png',
    },
    {
        id: 15,
        img: '/img/home/partner/15.png',
    },
    {
        id: 16,
        img: '/img/home/partner/16.png',
    },
    {
        id: 17,
        img: '/img/home/partner/17.png',
    },
    {
        id: 18,
        img: '/img/home/partner/18.png',
    },
]

export const customers = [
    {
        id: 1,
        font_img: '/img/home/customer/card1-front.png',
        back_img: '/img/home/customer/card1-back.png',
    },
    {
        id: 2,
        font_img: '/img/home/customer/card4-front.png',
        back_img: '/img/home/customer/card4-back.png',
    },
    {
        id: 3,
        font_img: '/img/home/customer/card5-front.png',
        back_img: '/img/home/customer/card5-back.png',
    },
    {
        id: 4,
        font_img: '/img/home/customer/card6-front.png',
        back_img: '/img/home/customer/card6-back.png',
    },
    {
        id: 5,
        font_img: '/img/home/customer/card7-front.png',
        back_img: '/img/home/customer/card7-back.png',
    },
    {
        id: 6,
        font_img: '/img/home/customer/card8-front.png',
        back_img: '/img/home/customer/card8-back.png',
    },
    // {
    //     id: 7,
    //     font_img: '/img/home/customer/card10-front.png',
    //     back_img: '/img/home/customer/card10-back.png',
    // },
    // {
    //     id: 8,
    //     font_img: '/img/home/customer/card11-front.png',
    //     back_img: '/img/home/customer/card11-back.png',
    // },
]