import Head from 'next/head'
import Link from 'next/link'
import { FaPlus } from 'react-icons/fa'

import { useAuth } from '@/hooks/auth'
import { useReactToPrint } from 'react-to-print'
import { useState, useRef } from 'react'
import Footer from '@/components/Layouts/Footer'
import QRCode from 'qrcode.react'
import Header from '@/components/Layouts/Header'
import html2canvas from 'html2canvas'
import PricingCard from './components/Pricing'
import { MdNoEncryptionGmailerrorred } from 'react-icons/md'
import { TfiWorld } from 'react-icons/tfi'
import { TbServerBolt } from 'react-icons/tb'
import { AiOutlineInteraction, AiTwotoneInteraction } from 'react-icons/ai'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SiSecurityscorecard } from 'react-icons/si'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-coverflow'

import 'swiper/css/pagination'

import { Navigation, A11y, EffectCoverflow, Pagination } from 'swiper/modules'
// import { Navigation, Pagination, EffectCoverflow } from 'swiper'

import Faq from '@/components/Faq'
import { customers, partners } from '@/DataIcon/HomeData'
export default function Home() {
    const styles = {
        // bgColor: 'white',
        titleTextColor: 'blue',
        rowTitleColor: 'blue',
        // rowContentColor: 'grey',
        // arrowColor: "red",
    }

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    }

    const { user } = useAuth({ middleware: 'guest' })
    const elementRef = useRef(null)

    const handleDownload = async () => {
        const element = elementRef.current

        if (!element) {
            console.error('Ref not found')
            return
        }

        try {
            const canvas = await html2canvas(element)
            const dataUrl = canvas.toDataURL('image/png')

            const link = document.createElement('a')
            link.download = 'smartcardgenerator.png'
            link.href = dataUrl
            link.click()
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const [inputField, setInputField] = useState({
        smarturl: '',
    })

    const inputsHandler = e => {
        e.persist()
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        })
    }
    const [showFront, setShowFront] = useState(true)
    const inputFieldRef = useRef(null)

    const handleSmartUrlClick = () => {
        // Trigger a click on the input field
        if (inputFieldRef.current) {
            inputFieldRef.current.focus()
        } else {
            console.error('Ref not initialized for input field')
        }
    }

    return (
        <>
            <Head>
                <meta charSet="UTF-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                <title>Smart Card Generator</title>
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://smartcardgenerator.net"
                />
                <meta
                    property="description"
                    content="Welcome to Smart Card Generator, your go-to destination for creating dynamic and efficient QR codes. We believe in simplifying the process of generating QR codes to empower businesses, individuals, and organizations with versatile and powerful tools."
                />
                <meta
                    property="og:description"
                    content="Welcome to Smart Card Generator, your go-to destination for creating dynamic and efficient QR codes. We believe in simplifying the process of generating QR codes to empower businesses, individuals, and organizations with versatile and powerful tools."
                />
                <meta property="og:type" content="Description" />
                <meta property="og:title" content="Smart Card Generator" />
                <meta
                    property="og:image"
                    content="/img/meta_image/home-page.jpg"
                />
                <meta
                    property="image"
                    content="/img/meta_image/home-page.jpg"
                />
                <meta property="og:site_name" content="Smart Card Generator" />
            </Head>
            <Header />
            {/* ============= Hero Section ================ */}
            <section className="hero-wrapper">
                <div
                    className="hero-section"
                    style={{
                        backgroundImage: 'url(./img/home/hero-img-2.png)',
                    }}>
                    <div className="container">
                        <div className="hero-content-item">
                            <h1>One Country, One Card</h1>
                            <p>
                                Welcome to Smart Card Generator, your one-stop
                                solution for creating dynamic QR codes. We make
                                it easy, offering powerful tools and a one-card
                                solution across various sectors, unlocking
                                unlimited facilities with a single tap.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* ============= Hero Section ================ */}
            <section className="smart-card-gengetor cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="yellow-filter main-filter"></div>
                <div className="container">
                    <div className="smart-card-gengetor-content">
                        <div className="row">
                            <div className="col-lg-7 col-md-12">
                                <div className="qr-input-item-wrapper">
                                    <div className="qr-input-items">
                                        <div className="qr-input-list-items">
                                            <div
                                                className="qr-input-list-item qr-input-list-item-btn"
                                                id="smarturl"
                                                onClick={handleSmartUrlClick}>
                                                <span>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.33789 17C4.21541 18.5207 5.478 19.7835 6.99859 20.6612C8.51919 21.539 10.2441 22.0007 11.9999 22C13.7556 22.0007 15.4806 21.539 17.0012 20.6612C18.5218 19.7835 19.7844 18.5207 20.6619 17M3.33789 7C4.21541 5.47928 5.478 4.21651 6.99859 3.33877C8.51919 2.46103 10.2441 1.99929 11.9999 2C13.7556 1.99929 15.4806 2.46103 17.0012 3.33877C18.5218 4.21651 19.7844 5.47928 20.6619 7"
                                                            stroke="#ffffff"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M13 21.95C13 21.95 14.408 20.097 15.295 17M13 2.04999C13 2.04999 14.408 3.90199 15.295 6.99999M11 21.95C11 21.95 9.592 20.098 8.705 17M11 2.04999C11 2.04999 9.592 3.90199 8.705 6.99999M9 9.99999L10.5 15L12 9.99999L13.5 15L15 9.99999M1 9.99999L2.5 15L4 9.99999L5.5 15L7 9.99999M17 9.99999L18.5 15L20 9.99999L21.5 15L23 9.99999"
                                                            stroke="#ffffff"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <p>Smart URL</p>
                                            </div>
                                            <Link
                                                href={
                                                    user
                                                        ? '/choose-template'
                                                        : '/login'
                                                }>
                                                <div className="qr-input-list-item">
                                                    <span>
                                                        <img
                                                            src="/img/2.svg"
                                                            alt=""
                                                        />
                                                    </span>
                                                    <p>Smart Resume</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="qr-input-list-items">
                                            <Link
                                                href={
                                                    user
                                                        ? '/createqr'
                                                        : '/login'
                                                }>
                                                <div className="qr-input-list-item">
                                                    <span>
                                                        <img
                                                            src="/img/1.svg"
                                                            alt=""
                                                        />
                                                    </span>
                                                    <p>Smart Visiting Card</p>
                                                </div>
                                            </Link>
                                            <Link
                                                href={
                                                    user
                                                        ? '/dashboard'
                                                        : '/login'
                                                }>
                                                <div className="qr-input-list-item">
                                                    <span>
                                                        <img
                                                            src="/img/3.svg"
                                                            alt=""
                                                        />
                                                    </span>
                                                    <p>Smart Profile Creator</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="qr-input-content">
                                        <h4>Enter your website</h4>
                                        <p>
                                            (Your QR Code will be generated
                                            automatically)
                                        </p>
                                        <div className="input-group mt-4">
                                            <input
                                                type="text"
                                                id="smarturl"
                                                className="form-control"
                                                name="smarturl"
                                                placeholder="Enter Your Website Link"
                                                onChange={inputsHandler}
                                                value={inputField.smarturl}
                                                ref={inputFieldRef}
                                            />
                                        </div>
                                        <Link href={'/register'}>
                                            <button className="custom-btn Signup for Free">
                                                Signup for Free
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="qr-code-item">
                                    <div
                                        className="qr-code-img"
                                        ref={elementRef}>
                                        <QRCode
                                            value={inputField.smarturl}
                                            size={260}
                                        />
                                        <Link
                                            href={'/login'}
                                            to={user ? '/website' : '/login'}>
                                            <div className="with-logo">
                                                <img
                                                    src="/img/icon/with-logo.png"
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                    <button
                                        className="qr-code-download-btn custom-btn"
                                        onClick={handleDownload}>
                                        DOWNLOAD
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ======== Input Website ============ */}

            {/* ============= Smart Card Plus Roadmap =========== */}
            <section className="vCard-roadmap">
                <div className="purple-filter main-filter"></div>
                <div className="green-filter main-filter"></div>
                <div className="yellow-filter main-filter"></div>
                <div className="container">
                    <div className="vCard-roadmap-wrapper">
                        <div className="vcard-roadmap-title">
                            <h4>How do I create a free Smart Card?</h4>
                        </div>
                        <div className="vcard-roadmap-content">
                            <div className="vcard-roadmap-content-items">
                                <div className="vcard-roadmap-content-item">
                                    <div className="roadmap-img">
                                        <img
                                            src="/img/home/roadmap-1.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="vcard-roadmap-content-item">
                                    <h3>Create a free account</h3>
                                    <p>
                                        Here you go, a general guide on creating
                                        a free account at Smart Card Generator.
                                        Initially locate the Register Button at
                                        the top right corner of the homepage and
                                        click on the designated button to
                                        initiate the account creation process.
                                        Afterwards, provide the required
                                        information such as name, email address,
                                        password and confirm password. Lastly,
                                        click on the Register button to complete
                                        your registration.
                                    </p>
                                </div>
                            </div>
                            <div className="vcard-roadmap-content-items vcard-roadmap-content-items-2">
                                <div className="vcard-roadmap-content-item">
                                    <h3>Fill in the details</h3>
                                    <p>
                                        You must need to put values in all the
                                        required fields for registration along
                                        with generating a QR code such as
                                        display picture, name, phone numbers,
                                        email address, living address, and
                                        company information. You will not be
                                        able to submit the QR-generating form
                                        unless you fill in all the required
                                        details.
                                    </p>
                                </div>
                                <div className="vcard-roadmap-content-item vcard-roadmap-content-item2">
                                    <div className="roadmap-img">
                                        <img
                                            src="/img/home/roadmap-2.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="vcard-roadmap-content-items">
                                <div className="vcard-roadmap-content-item ">
                                    <div className="roadmap-img">
                                        <img
                                            src="/img/home/roadmap-3.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="vcard-roadmap-content-item vcard-roadmap-content-item3">
                                    <h3>Download the QR Code</h3>
                                    <p>
                                        Once you have provided all necessary
                                        information and completed generating the
                                        QR code, The site will take you to your
                                        dashboard where you will be able to see
                                        your QR code and download option.
                                        Clicking the download button, you have
                                        downloaded the QR code linked with your
                                        personal profile.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <PaymentLogoImg/> */}

            {/* ============= Card Options ============ */}
            <section className="card-option cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="yellow-filter main-filter"></div>
                <div className="container">
                    <div className="card-option-headers">
                        <h1 className="section-title">Card options</h1>
                        <Link href={'/'}>
                            <button className="custom-btn">Order Now</button>
                        </Link>
                    </div>
                    <div className="card-option-wrapper">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="card-option-item">
                                    <div className="card-option-img-wrap">
                                        <div className="card-img">
                                            <img
                                                src="/img/home/card-1.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="card-option-choose">
                                            <Link href={'/'}>
                                                <button className="white-card-btn">
                                                    Customizable
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-option-content">
                                        <Link href={'/'}>
                                            <a>Classic</a>
                                        </Link>
                                        <p>
                                            Our most popular digital business
                                            card option, crafted from durable,
                                            recyclable plastic that can be
                                            reused up to eight times. Designed
                                            for longevity and produced with
                                            precision, it's the ideal choice for
                                            any occasion.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card-option-item">
                                    <div className="card-option-img-wrap">
                                        <div className="card-img">
                                            <img
                                                src="/img/home/card-2.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="card-option-choose">
                                            <input
                                                type="radio"
                                                className="wood-1"
                                                name="wood-option"
                                            />
                                            <input
                                                type="radio"
                                                className="wood-2"
                                                name="wood-option"
                                            />
                                            <Link href={'/'}>
                                                <button className="wood-card-btn">
                                                    Customizable
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-option-content">
                                        <Link href={'/'}>
                                            <a>Wood</a>
                                        </Link>
                                        <p>
                                            This eco-friendly networking
                                            solution is sure to leave a lasting
                                            impression—and for every card sold,
                                            we plant a tree. Available in light
                                            Birch and dark Sapele, both
                                            responsibly sourced.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card-option-item">
                                    <div className="card-option-img-wrap">
                                        <div className="card-img">
                                            <img
                                                src="/img/home/card-3.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="card-option-choose">
                                            <input
                                                type="radio"
                                                className="metal-1"
                                                name="metal"
                                            />
                                            <input
                                                type="radio"
                                                className="metal-2"
                                                name="metal"
                                            />
                                            <input
                                                type="radio"
                                                className="metal-3"
                                                name="metal"
                                            />

                                            <Link href={'/'}>
                                                <button className="wood-card-btn">
                                                    Customizable
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-option-content">
                                        <Link href={'/'}>
                                            <a>Metal</a>
                                        </Link>
                                        <p>
                                            Crafted from stainless steel and
                                            laser-engraved, this card commands
                                            attention. Available in Black, Gold,
                                            and Silver. Gold and Silver cards
                                            feature black engraving, while Black
                                            cards are engraved to reveal the
                                            stainless steel beneath.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row nfc-wrapper">
                            <div className="col-md-6">
                                <div className="card-option-nfc-item">
                                    <div className="card-nfc-img">
                                        <img src="/img/home/nfc-1.png" alt="" />
                                    </div>
                                    <div className="card-option-nfc-content">
                                        <h3>NFC Key fob</h3>
                                        <p>
                                            Keep this NFC key fob on your
                                            keychain, ensuring you always have
                                            your digital card with you, no
                                            matter where you go.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card-option-nfc-item">
                                    <div className="card-nfc-img">
                                        <img src="/img/home/nfc-2.png" alt="" />
                                    </div>
                                    <div className="card-option-nfc-content">
                                        <h3>NFC Smart Sticker</h3>
                                        <p>
                                            Place this NFC smart sticker on the
                                            back of your phone, ensuring you're
                                            always ready to make a connection.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ========== Customers ========= */}
            <section className="customers cpb-6">
                <div className="purple-filter main-filter"></div>
                <div className="green-filter main-filter"></div>
                <div className="container">
                    <div className="customer-header">
                        <h1 className="section-title">
                            Check Out These Customers
                        </h1>
                        <p>
                            Companies big and small love digital business cards.
                            We have onboarded over 1,000 teams including
                            intermational banks, construction companies, retail,
                            roofing, plumbing, marketing agencies, hospitals and
                            many more.
                        </p>
                    </div>
                    <div className="customer-wrapper">
                        <div className="customer-content">
                            <Swiper
                                centeredSlides={true}
                                loop={true}
                                navigation={{
                                    nextEl: '.button-next',
                                    prevEl: '.button-prev',
                                }}
                                modules={[Navigation]}
                                className="mySwiper">
                                {customers.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="customer-item">
                                            <div
                                                className="front-img"
                                                style={{
                                                    display: showFront
                                                        ? 'block'
                                                        : 'none',
                                                }}>
                                                <img
                                                    src={item.font_img}
                                                    alt=""
                                                />
                                            </div>
                                            <div
                                                className="back-img"
                                                style={{
                                                    display: showFront
                                                        ? 'none'
                                                        : 'block',
                                                }}>
                                                <img
                                                    src={item.back_img}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className="customer-btn">
                                <div className="button-prev">
                                    <img
                                        src="/img/home/left-arrow.svg"
                                        alt="Previous"
                                    />
                                </div>
                                <div className="button-next">
                                    <img
                                        src="/img/home/right-arrow.svg"
                                        alt="Next"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="customer-footer-item">
                            <div className="customer-btns customer-footer-btn">
                                <button onClick={() => setShowFront(true)}>
                                    Front
                                </button>
                                <span> / </span>
                                <button onClick={() => setShowFront(false)}>
                                    Back
                                </button>
                            </div>
                            <Link href={user ? '/' : '/login'}>
                                <button className="custom-btn customer-footer-btn">
                                    Design My Own
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* ========== Card Security =============== */}
            <section className="card-security cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="green-filter main-filter"></div>
                <div className="container">
                    <div className="security-header">
                        <p>FEATURES OF</p>
                        <h1 className="section-title">Smart Card Security</h1>
                    </div>
                    <div className="security-content">
                        <div className="security-item">
                            <div className="security-icon">
                                <MdNoEncryptionGmailerrorred />
                            </div>
                            <h1>HTTPS</h1>
                            <p>
                                Ensure all data transmitted between users and
                                your web portal is encrypted by using an SSL/TLS
                                certificate, enforcing HTTPS for all
                                connections.
                            </p>
                        </div>
                        <div className="security-item">
                            <div className="security-icon">
                                <TfiWorld />
                            </div>
                            <h1>Data Encryption</h1>
                            <p>
                                Encrypt all sensitive data both in transit and
                                at rest using strong encryption algorithms
                                (e.g., AES-256).
                            </p>
                        </div>
                        <div className="security-item">
                            <div className="security-icon">
                                <SiSecurityscorecard />
                            </div>
                            <h1>Regular Security Audits</h1>
                            <p>
                                Conduct frequent security audits and
                                vulnerability assessments to identify and fix
                                potential weaknesses.
                            </p>
                        </div>
                        <div className="security-item">
                            <div className="security-icon">
                                <AiOutlineInteraction />
                            </div>
                            <h1>Input Validation and Sanitization</h1>
                            <p>
                                Protect against injection attacks (e.g., SQL
                                injection, XSS) by validating and sanitizing all
                                user inputs.
                            </p>
                        </div>
                        <div className="security-item">
                            <div className="security-icon">
                                <TbServerBolt />
                            </div>
                            <h1>Web Application Firewall (WAF)</h1>
                            <p>
                                Deploy a WAF to monitor and block malicious
                                traffic, protecting against common web attacks.
                            </p>
                        </div>
                        <div className="security-item">
                            <div className="security-icon">
                                <TbServerBolt />
                            </div>
                            <h1>Input Validation and Sanitization</h1>
                            <p>
                                Protect against injection attacks (e.g., SQL
                                injection, XSS) by validating and sanitizing all
                                user inputs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="pricing-area cpy-6">
                    <div className="purple-filter main-filter"></div>
                    <div className="yellow-filter main-filter"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="pricing-area-title">
                                    <h2>
                                        Plans & <span>Pricing</span>
                                    </h2>
                                    <p>Find a plan that suits your needs</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <PricingCard />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* =============== How it works =============*/}
            <section className="how-it-work cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="yellow-filter main-filter"></div>
                <div className="container">
                    <h1 className="section-title">How It Works</h1>
                    <div className="how-it-work-content">
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="how-it-work-item">
                                    <div className="how-work-img">
                                        <img
                                            src="/img/home/work-1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="how-work-content">
                                        <span>Step 1</span>
                                        <h2>ORDER</h2>
                                        <p>
                                            Choose a card and any add-ons, then
                                            proceed to complete your order and
                                            payment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="how-it-work-item">
                                    <div className="how-work-img">
                                        <img
                                            src="/img/home/work-2.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="how-work-content">
                                        <span>Step 2</span>
                                        <h2>DESIGN</h2>
                                        <p>
                                            Submit your design preferences and
                                            upload your logo via the design
                                            portal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="how-it-work-item">
                                    <div className="how-work-img">
                                        <img
                                            src="/img/home/work-3.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="how-work-content">
                                        <span>Step 3 </span>
                                        <h2>REVIEW</h2>
                                        <p>
                                            Review the digital design proof and
                                            share your feedback.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="how-it-work-item">
                                    <div className="how-work-img">
                                        <img
                                            src="/img/home/work-4.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="how-work-content">
                                        <span>Step 4 </span>
                                        <h2>PRINT & SHIP</h2>
                                        <p>
                                            Once approved, we will program,
                                            produce, and ship your card.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ================ Partners =========== */}
            <section className="partner">
                <div className="green-filter main-filter"></div>
                <div className="container">
                    <h1 className="section-title text-center">Partners</h1>
                    <div className="partner-content">
                        <div className="row">
                            {partners.map((item, index) => (
                                <div
                                    className="col-md-3 col-sm-6 col-6 col-lg-2"
                                    key={index}>
                                    <div className="partner-img">
                                        <img src={item.img} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-center cpt-9 cpb-6">
                        <button className="custom-btn browse-more-btn">
                            Browse More
                        </button>
                    </div>
                </div>
            </section>
            {/* ============= What Our Client Says =========== */}
            <section className="client-say cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="yellow-filter main-filter"></div>
                <div className="container">
                    <h1 className="section-title text-center">
                        What<span> Our Client Says</span>
                    </h1>
                    <div className="client-say-wrapper">
                        <Swiper
                            modules={[Navigation, Pagination, EffectCoverflow]}
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={2.5} // Show 3 items (1 main large, 2 smaller)
                            spaceBetween={20} // Gap between slides
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 200,
                                modifier: 1,
                                slideShadows: false, // You can enable this if you want shadows
                                scale: 0.8, // Controls the size difference between the main and side slides
                            }}
                            breakpoints={{
                                320: { slidesPerView: 1 },
                                600: { slidesPerView: 2 },
                                768: { slidesPerView: 2.5 },
                            }}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            navigation={{
                                prevEl: '.prev',
                                nextEl: '.next',
                            }}
                            loop={true}>
                            <SwiperSlide>
                                <div className="client-say-item">
                                    <div className="client-icon-img">
                                        <img
                                            src="/img/home/client-icon.png"
                                            alt="Client Icon"
                                        />
                                    </div>
                                    <div className="client-img">
                                        <img
                                            src="/img/home/abul-khair.jpg"
                                            alt="Client"
                                        />
                                    </div>
                                    <div>
                                        <h2>Abdul Khair Patwary</h2>
                                        <h4>Ex. Mayor Ramganj Pouroshova</h4>
                                    </div>
                                    <p>
                                        I appreciate how the smart visiting card
                                        combines technology with simplicity.
                                        It’s user-friendly, and the QR code
                                        feature is a great touch. It’s a
                                        must-have for professionals!
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="client-say-item">
                                    <div className="client-icon-img">
                                        <img
                                            src="/img/home/client-icon.png"
                                            alt="Client Icon"
                                        />
                                    </div>
                                    <div className="client-img">
                                        <img
                                            src="/img/home/lori.jpg"
                                            alt="Client"
                                        />
                                    </div>
                                    <div>
                                        <h2>Lori Elizabeth Souza</h2>
                                        <h4>Founder, Women in Crypto</h4>
                                    </div>
                                    <p>
                                        A perfect tool for modern networking.
                                        The smart card is easy to use, and it
                                        adds a unique touch to my professional
                                        interactions. Highly satisfied!
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="client-say-item">
                                    <div className="client-icon-img">
                                        <img
                                            src="/img/home/client-icon.png"
                                            alt="Client Icon"
                                        />
                                    </div>
                                    <div className="client-img">
                                        <img
                                            src="/img/home/03-bidisha.jpg"
                                            alt="Client"
                                        />
                                    </div>
                                    <div>
                                        <h2>Bidisha Roy Das</h2>
                                        <h4>
                                            Founder & CEO Rupantar Education
                                        </h4>
                                    </div>
                                    <p>
                                        Incredibly innovative! My clients are
                                        always amazed when I share my contact
                                        details with just one tap. It’s an
                                        excellent way to leave a lasting
                                        impression.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="client-say-item">
                                    <div className="client-icon-img">
                                        <img
                                            src="/img/home/client-icon.png"
                                            alt="Client Icon"
                                        />
                                    </div>
                                    <div className="client-img">
                                        <img
                                            src="/img/home/04-Inshyah.jpg"
                                            alt="Client"
                                        />
                                    </div>
                                    <div>
                                        <h2>Insiyah Zohair Ali</h2>
                                        <h4>
                                            Head of School Frobel Play School
                                        </h4>
                                    </div>
                                    <p>
                                        I love how convenient the smart visiting
                                        card is. Just one tap, and all my
                                        details are instantly shared. It's
                                        modern, eco-friendly, and impressive!
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="client-say-item">
                                    <div className="client-icon-img">
                                        <img
                                            src="/img/home/client-icon.png"
                                            alt="Client Icon"
                                        />
                                    </div>
                                    <div className="client-img">
                                        <img
                                            src="/img/home/05-shelle.jpg"
                                            alt="Client"
                                        />
                                    </div>
                                    <div>
                                        <h2>Shelle Fantastic </h2>
                                        <h4>Founder & CEO SheFan Pro</h4>
                                    </div>
                                    <p>
                                        Thank you, One card, and Women In
                                        Digital for sending this card all the
                                        way from Bangladesh! I received it
                                        today, and I absolutely love the
                                        packaging. I’m so excited! Oh my
                                        goodness, this is such a smart card! I’m
                                        thrilled to have my very own
                                        personalized digital smart visiting card
                                        with my picture, details, and even a QR
                                        code. I absolutely love it! Thank you so
                                        much, Women In Digital.
                                    </p>
                                </div>
                            </SwiperSlide>
                            {/* Add more SwiperSlide components as needed */}
                        </Swiper>
                        <div className="client-btn">
                            <div className="prev">
                                <img
                                    src="/img/home/arrow-right.png"
                                    alt="Previous"
                                />
                            </div>
                            <div className="swiper-pagination"></div>
                            <div className="next">
                                <img
                                    src="/img/home/arrow-left.png"
                                    alt="Previous"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ============ Faq ================ */}
            <Faq />
            <Footer />
        </>
    )
}
