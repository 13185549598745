import React, { useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { AiOutlinePlus } from 'react-icons/ai'


const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null)
    const [faqCateActive, setFaqCateActive] = useState(0)
    const [faq, setFaq] = useState([]);
    const baseuri = process.env.NEXT_PUBLIC_BACKEND_URL;

    // Toggles the active FAQ item
    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index)
    }

    // Handles category change
    const faqCateHandle = index => {
        setFaqCateActive(index)
        setActiveIndex(null) // Reset activeIndex when changing categories
    }

    useEffect(() => {
        // Face data
        const fetchFAQ = async () => {
            try {
                const response = await fetch(`${baseuri}/api/faqs`);
                const data = await response.json();
                setFaq(data);
                setFaqCateActive(data[0].id)
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchFAQ();
    }, []);
    // console.log('FAQ : ', faq);


    return (
        <>
            <section className="faq-question cpy-6">
                <div className="purple-filter main-filter"></div>
                <div className="green-filter main-filter"></div>
                <div className="container">
                    <div className="faq-wrapper">
                            <div className="faq-left">
                                <h1>Frequently Asked Questions</h1>
                                {faq.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`faq-item ${faqCateActive === item.id ? 'active' : ''}`}
                                        onClick={() => faqCateHandle(item.id)}
                                    >
                                        <h4>{item.title}</h4>
                                    </div>
                                ))}
                            </div>
                        <div className="faq-right">

                            <div className="faq-right">
                                {faq.map((item, index) => (
                                    faqCateActive === item.id && (
                                        <div key={index}>
                                            {item.questions.map((question, index) => (
                                                <div className="faq-ans-qes-item" key={index}>
                                                    <div
                                                        className={`faq-ques-item ${activeIndex === index ? 'active' : ''}`}
                                                        onClick={() => toggleFAQ(index)}
                                                    >
                                                        <h4>{question?.question}</h4>
                                                        <div className="faq-icon">
                                                            {activeIndex === index ? (
                                                                <IoMdClose />
                                                            ) : (
                                                                <AiOutlinePlus />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="faq-ans-item">
                                                        {activeIndex === index && (
                                                            <p>{question?.answer}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                ))}
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq

const businessCard = {
    rows: [
        {
            title: 'Do One cards work with my phone?',
            content: `If you have a smartphone, it's highly likely that NFC is enabled. All iPhone models from the XR (September 2€18) onward and roughly 90% of Android phones support NFC.

            Additionally, every smartphone, whether Apple or Android, comes with a built-in QR code scanner. Just open your camera and point it at the QR code on the back of each One card.`,
        },
        {
            title: 'What materials are One cards made of?',
            content:
                'Our Classic cards are crafted from high-quality PET plastic, which is recyclable up to eight times. The embedded chip, composed of various durable materials, typically lasts for several years. Additionally, we offer Wood and Metal cards, made from sustainably sourced Birch or Sapele wood, and stainless steel.',
        },
        {
            title: 'Can I order multiple One cards?',
            content: `Absolutely! You can order as many cards as you need. Once your cards arrive, you can invite individuals to sign up and manage everything through an easy-to-use backend interface. Add your entire team and effortlessly transfer card ownership as members join or leave your organization.
`,
        },
        {
            title: 'How do digital business cards work?',
            content: `Each One card is equipped with a chip that wirelessly transmits your information to a smartphone. When tapped on a compatible phone, a contact form appears with your details automatically filled in, and the recipient simply needs to press 'save.' This seamless contact transfer is one of four unique modes available with every Mobilo card. Additionally, each One card includes a QR code on the back to ensure compatibility with older smartphones that lack NFC technology.
`,
        },
        {
            title: 'Can I update the information on my One card?',
            content: `Yes, once your card is shipped, you'll receive an automated email with login instructions. Through your account dashboard, you can update your contact information at any time, with changes being applied to your card instantly in real-time.
`,
        },
    ],
}
const cardDesign = {
    rows: [
        {
            title: 'Can I get a One card with my own branding?',
            content: `The Custom card can be personalized with your own design. After placing your order, our design team will collaborate with you to ensure your design is flawless. You'll receive a digital print proof for approval before production begins. Once approved, we'll begin crafting your card.

`,
        },
        {
            title: 'What is the difference between a One card and a Custom card?',
            content:
                'The One card and Custom card offer the same functionality. However, the One card features One card branding on both the front and back, while the Custom card can be personalized with your own design or logo.',
        },

    ],
}
const specialOption = {
    rows: [
        {
            title: 'Can I give One cards as gifts?',
            content: `Yes! When placing your order, simply leave a note indicating that the One card is a gift for someone else. By default, the card is assigned to the person who places the order.
`,
        },
        {
            title: 'Does One offer any deals or discounts?',
            content:
                'We occasionally offer discounted rates based on the size of your organization. Contact us here, and we’ll be happy to discuss how we can assist you.',
        },
        {
            title: 'Can I become a reseller of One cards?',
            content: `Unfortunately, no. We currently don't offer reseller options. However, if you have a significant opportunity, we'd be happy to discuss a commission for referring the deal.
`,
        },

    ],
}
const dataSecurity = {
    rows: [
        {
            title: 'How does Mobilo ensure the security and privacy of data in their smart visiting cards, and what technologies are used to protect information from unauthorized access and tampering?',
            content: `In our smart visiting card, we take data security very seriously. We’ve implemented blockchain technology, which is one of the most secure methods for handling and storing data. Blockchain provides a decentralized and tamper-proof way to protect your information, ensuring that your data remains safe and cannot be altered or accessed by unauthorized parties. Additionally, we encrypt all data, adding another layer of security that ensures your information is protected both during transmission and while stored. Rest assured, your privacy and data security are our top priorities, and we use the most advanced technologies to safeguard them.
`,
        },

    ],
}
