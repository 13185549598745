import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/hooks/auth'
import { useRouter } from 'next/router'
import { IoMdMenu } from 'react-icons/io'
import { MdOutlineClose } from 'react-icons/md'

const Header = () => {
    const { user, logout } = useAuth({ middleware: 'guest' })

    const [sticky, setSticky] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const router = useRouter()

    const isActive = pathname => router.pathname === pathname
 const [active, setActive] = useState(false)
 const handleMenuToggle = () => {
     setActive(!active)
 }
 const handleMenuClose = () => {
     setActive(false)
 }
    return (
        <>
            <header className={`${sticky ? 'is-sticky' : ''} menu-nav `}>
                <div className="container">
                    <div className="home-header">
                        <div className="logo">
                            <Link href={'/'}>
                                <a>
                                    <img src="/img/logo.png" alt="" />
                                </a>
                            </Link>
                        </div>
                        <button
                            className="menu-bar-btn"
                            onClick={handleMenuToggle}>
                            <IoMdMenu />
                        </button>
                        <div className={`menu ${active ? 'active' : ''}`}>
                            <button
                                className="menu-close-btn"
                                onClick={handleMenuClose}>
                                <MdOutlineClose />
                            </button>
                            <ul>
                                <li className={isActive('/') ? 'active' : ''}>
                                    <Link href="/">Home</Link>
                                </li>
                                <li
                                    className={
                                        isActive('/about') ? 'active' : ''
                                    }>
                                    <Link href="/about">About Us</Link>
                                </li>
                                {/* <li
                                    className={
                                        isActive('/') ? 'active' : ''
                                    }>
                                    <Link href="/">Blog</Link>
                                </li> */}
                                <li
                                    className={
                                        isActive('/contact-us') ? 'active' : ''
                                    }>
                                    <Link href="/contact-us">Contact Us</Link>
                                </li>
                                {user ? (
                                    user.email_verified_at ? (
                                        <>
                                            <li className="custom-btn">
                                                <Link href="/dashboard">
                                                    Dashboard
                                                </Link>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="custom-btn ">
                                                <button onClick={logout}>
                                                    Logout
                                                </button>
                                            </li>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <li className="custom-btn header-login-btn">
                                            <Link href="/login">Login</Link>
                                        </li>
                                        <li className="custom-btn-alt">
                                            <Link href="/register">
                                                Register
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
